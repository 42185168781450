@import url("https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Public+Sans:ital,wght@0,400;0,700;1,500&display=swap");

* {
  scroll-behavior: smooth;
}

@font-face {
  font-family: "Freshman";
  src: local("Freshman"), url("./assets/fonts/freshman.woff2") format("woff2");
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "Crossroad";
  src: local("Crossroad"),
    url("./assets/fonts/crossroads.woff2") format("woff2");
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "Radley";
  src: local("Radley"), url("./assets/fonts/radley.woff2") format("woff2");
  font-weight: bold;
  font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;600;900&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply tracking-tight font-bold text-black;
}

h1 {
  @apply text-4xl md:text-5xl;
}

h2 {
  @apply text-3xl md:text-4xl;
}

h3 {
  @apply text-2xl md:text-3xl;
}

p {
  @apply font-normal text-black;
}

a {
  @apply text-teal hover:underline;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

form {
  @apply w-full;
}

input {
  @apply bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-claro-500;
}

.blueButton {
  @apply mr-1 ml-1 mt-2 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded;
}

.container {
  width: 100%;
  max-width: 100vw;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .container {
    max-width: 1200px;
  }
}

.container-landing {
  width: 100%;
  max-width: 100vw;
  margin: 0 auto;
}

.landing-hero {
  height: calc(70vh - 100px);
  height: calc(70svh - 100px);
}

@media screen and (min-width: 768px) {
  .container-landing {
    max-width: 800px;
  }

  .landing-hero {
    height: calc(70vh - 100px);
    height: calc(70svh - 100px);
  }
}

.swiper-button-next{
  color:#000
}

.swiper-button-prev{
  color:#000
}